<template>
  <section>
    <div>
      <template v-if="!fromQuickAction">
        <span v-if="isActive" class="setting-icon" @click="openSettings">
          <i class="el-icon-s-tools" />
        </span>
        <el-link
          :loading="loading"
          v-if="
            data &&
            data.action_button_type &&
            data.action_button_type == 'HYPERLINK'
          "
          :style="hideStyles ? getGalleryStyles : getStyle"
          @click="action"
          :disabled="checkReadonly"
          >{{ label || data.label }}</el-link
        >
        <el-button
          :loading="loading"
          :style="hideStyles ? '' : getStyle"
          :round="isRound"
          :size="getSize"
          v-else
          @click="action"
          :disabled="checkReadonly"
          >{{ label || data.label }}</el-button
        >
      </template>
      <dialog-component
        :title="
          viewDataOnly || viewDataAndAdd
            ? 'Related ' + (relationshipDetails.relationship_title || 'data')
            : allowNewAdd
            ? 'Add or assign'
            : 'Assign'
        "
        :visible="openRelationalModal"
        @before-close="resetAddModal"
        :containerWidth="getIsMobile ? '100%' : '50vw'"
        :containerMaxHeight="'90%'"
      >
        <!-- <el-scrollbar wrap-style="max-height: 1000px; padding-right: 10px;"> -->
        <quick-entity-filter
          v-if="
            relationshipDetails &&
            relationshipDetails.parent_entity_id &&
            openRelationalModal
          "
          :openRelationalModal="openRelationalModal"
          :entityId="relationshipDetails.relationship_id"
          :relationshipDetails="relationshipDetails"
          :allowNewAdd="allowNewAdd"
          :actionButtonField="data"
          :viewDataOnly="viewDataOnly"
          :viewDataAndAdd="viewDataAndAdd"
          :allowNewAddOnly="allowNewAddOnly"
          @quickAddClose="resetAddModal"
          @entityDataSelect="updateEntityData"
          :parentFields="fieldsData"
          :parentForm="form"
          :parentEntityData="entityData"
          style="padding: 10px"
        ></quick-entity-filter>
        <!-- </el-scrollbar> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetAddModal">
            {{ viewDataOnly || viewDataAndAdd ? "Close" : "Cancel" }}
          </el-button>
          <el-button
            v-if="!viewDataOnly && !viewDataAndAdd"
            type="primary"
            @click="establichRelationship"
            :disabled="checkDisabled"
            :loading="loading"
            >Save</el-button
          >
        </span>
      </dialog-component>
      <dialog-component
        title="Add entity data"
        :visible="openOtherEntityModal"
        @before-close="resetOtherEntityModal"
        :containerMaxHeight="'70%'"
      >
        <quickEntityAddWidget
          :entityId="selectedOtherEntityId"
          :actionButtonField="data"
          :currentEntity="currentEntity"
          :openNewAdd="true"
          :relationshipDetails="[]"
          :detailed_add="true"
          @onNewEntity="resetOtherEntityModal(true)"
          :parentFields="fieldsData"
          :parentForm="form"
          :parentEntityData="entityData"
          :isFromAddOtherEntity="isFromAddOtherEntity"
          :otherEntityDataId="parent_entityDataId"
          :isUpdateEntityData="isUpdateEntityData"
        ></quickEntityAddWidget>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetOtherEntityModal">Cancel</el-button>
          <el-button type="primary">Save</el-button>
        </span>
      </dialog-component>
      <dialog-component
        :title="'AI Match'"
        :visible="openRelationalModalForAiMatch"
        @before-close="resetAddModal"
        :containerWidth="computedContainerWidth"
        :containerHeight="'80%'"
        :isShowFooter="false"
      >
        <div class="container-fields sticky-fields">
          <el-row :gutter="30">
            <el-col :span="6">
              <div class="form-group">
                <label for="select-entity" class="form-label">Entity</label>
                <el-select
                  v-model="selected_entity_for_aimatch"
                  placeholder="Select Entity"
                  filterable
                  @change="handleEntityChange"
                >
                  <el-option
                    v-for="(entity, index) in allEntitiesData"
                    :key="index"
                    :label="entity.name"
                    :value="entity._id"
                  />
                </el-select>
              </div>
            </el-col>

            <el-col :span="6">
              <div class="form-group">
                <label for="select-field" class="form-label">Fields</label>
                <el-select
                  ref="fieldsDropdown"
                  v-model="selected_fields"
                  placeholder="Select Entity Fields"
                  :disabled="!selected_entity_for_aimatch"
                  multiple
                  collapse-tags
                  filterable
                  @change="handleSelectAllUnselectAll"
                >
                <el-option
                v-if="selected_fields.length !== filteredEntityFields.length"
                :key="'selectAllOptions'"
                :value="'selectAllOptions'"
                :label="'Select All'"
                >
                <strong>Select All</strong>
              </el-option>
              <el-option
              v-if="selected_fields.length > 0"
              :key="'unselectAllOptions'"
              :value="'unselectAllOptions'"
              :label="'Unselect All'"
              >
              <strong>Unselect All</strong>
            </el-option>
                  <el-option
                    v-for="(item, index) of filteredEntityFields"
                    :key="index"
                    :value="item.key"
                    :label="`${item.template_name} - ${item.label}`"
                  />
                </el-select>
              </div>
            </el-col>

            <el-col :span="6">
              <div class="form-group">
                <label for="custom-prompt" class="form-label"
                  >Custom Prompt</label
                >
                <div class="custom-textarea-container">
                  <el-input
                    v-model="customPrompt"
                    placeholder="Custom Prompt"
                    class="custom-textarea"
                    clearable
                  ></el-input>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-group">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <el-button
                    type="primary"
                    style="height: 35px; letter-spacing: 1px; margin-top: 25px"
                    :disabled="
                      !selected_entity_for_aimatch || !selected_fields.length
                    "
                    @click="generateResponse"
                  >
                    <span class="scan-sty">
                      Scan
                      <i class="el-icon-search"></i>
                    </span>
                  </el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="isDataVisible">
          <div class="main-table-holder ">
            <el-scrollbar
              wrap-style="max-height: 45vh;scrollbar-width:thin;overflow-x:auto"
            >
              <div class="vue-data-table-default">
                <el-table
                  :resizable="false"
                  :data="paginatedData"
                  style="width: 100%; margin-top: 20px; table-layout: auto"
                  border
                >
                  <el-table-column
                    type="selection"
                    width="55"
                  ></el-table-column>
                  <el-table-column
                    v-for="(field, index) in selected_fields"
                    :key="index"
                    :label="getFieldLabel(field)"
                    :min-width="200"
                  >
                    <template slot="header" slot-scope="{ column }">
                      <el-tooltip
                        :content="getMatchedAttributesTooltip(field)"
                        placement="top"
                      >
                        <span>{{ column.label }}</span>
                      </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                      <div v-if="scope.row">
                        {{ getCurrentFieldValue(scope.row, field) }}
                        <span
                          v-if="
                            isMatched(
                              scope.row.result?.Matched_Attributes,
                              field
                            )
                          "
                        >
                          <img
                            src="@/assets/check-circle-fill.svg"
                            alt="Matched"
                          />
                        </span>
                        <span
                          v-else-if="
                            isNonMatched(
                              scope.row.result?.['Non-Matched_Attributes'],
                              field
                            )
                          "
                        >
                          <img src="@/assets/x-circle-fill.svg" alt="Matched" />
                        </span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="Overall Matching %" min-width="150">
                    <template slot-scope="scope">
                      <el-progress
                        :percentage="scope.row.percentage"
                        :stroke-width="6"
                        type="circle"
                        :color="getProgressColor(scope.row.percentage)"
                        :width="50"
                      ></el-progress>
                    </template>
                  </el-table-column>

                  <el-table-column label="Overall Summary" min-width="250">
                    <template
                      v-if="
                        scope.row &&
                        scope.row.result &&
                        scope.row.result['Overall Fit']
                      "
                      slot-scope="scope"
                    >
                      <span>
                        {{ truncateText(scope.row.result["Overall Fit"], 100) }}
                        <a
                          v-if="scope.row.result['Overall Fit'].length > 100"
                          @click="
                            openSummaryDialog(scope.row.result['Overall Fit'])
                          "
                          style="
                            color: #409eff;
                            cursor: pointer;
                            margin-left: 5px;
                          "
                        >
                          Read More
                        </a>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Actions"
                    width="150"
                    :min-width="getIsMobile ? '120' : '150'"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      <el-tooltip content="Preview" placement="top">
                        <!-- <el-button type="text" @click="viewResume(scope.row)"> -->
                        <el-button type="text" @click="viewData(scope.row)">
                          <i class="fa fa-eye"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip content="Redirect To" placement="top">
                        <el-button
                          type="text"
                          @click="redirectToEntityData(scope.row)"
                        >
                          <i class="el-icon-top-right" circle></i>
                        </el-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
          </div>
        </div>

        <div v-if="isDataVisible">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="aiMatchingResponse.length"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[5, 10, 20, 50]"
            style="margin-top: 20px; text-align: center"
          ></el-pagination>
        </div>
        <div v-if="LoadingDialogVisible" class="custom-popup-overlay">
          <div class="custom-popup-box">
            <div class="text-center">
              <img
                src="@/assets/img/Analyzing.gif"
                class="import-loading-gif"
                style="height: 10vh; width: 10vh"
              />
              <p>Please wait!</p>
              <h3>
                We are searching for matching data. This may take a few moments.
              </h3>
            </div>
          </div>
        </div>
        <div v-if="!isDataVisible && !LoadingDialogVisible" class="no-data-box">
          <div class="no-data-content">
            <div class="no-data-icon">
              <img src="@/assets/img/nodata2.svg" />
            </div>
            <p>No Data Found</p>
          </div>
        </div>
      </dialog-component>
      <dialog-component
        :title="'Overall Summary'"
        :visible="showDialog"
        :containerWidth="getIsMobile ? '100%' : '50vw'"
        @before-close="showDialog = false"
        :isShowFooter="false"
      >
        <p style="padding: 10px">{{ selectedSummary }}</p>
      </dialog-component>
      <complete-entity-data-popup
        :entityId="checkAiMatch.selected_entity_for_aimatch"
        :entityDataId="selectedEntityData._id"
        :isTitleVisible="true"
        v-if="showViewDialog"
        @close="handleCloseViewDialog"
      >
      </complete-entity-data-popup>
    </div>
  </section>
</template>

<script>
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import { makeSlug } from "../../../helpers/appHelper";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
import JSZip from "jszip";
import axios from "axios";
import { postAPICall } from "@/helpers/httpHelper";
import AutomationHelper from "@/mixins/AutomationHelper";
import {  fetchEntityById } from "@/repo/entityRepo";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import FieldsFormatHelper from "../../../mixins/FieldsFormatHelper";

// import { bus } from "../../../main";
export default {
  name: "templates-formComponentsExecute-ActionButtonExecute",
  props: [
    "field",
    "data",
    "label",
    "form",
    "isActive",
    "workflowDataId",
    "entityId",
    "entityDataId",
    "fieldsData",
    "parentFields",
    "checkIsDisabled",
    "hideStyles",
    "isView",
    "selectedTableRows",
    "fromQuickAction",
    "entity_type",
    "entityData",
    "selfTemplateId",
    "templateName",
    "allEntitiesData"
  ],
  mixins: [
    AutomationHelper,
    templateBuilderHelper,
    userPermissionsHelper,
    EntityHelper,
    CustomDashboardConfig,
    FieldsFormatHelper,
  ],
  components: {
    CompleteEntityDataPopup: () =>
      import("@/components/entity/completeEntityDataPopup.vue"),
    QuickEntityFilter: () => import("../../widgets/EntityAddSelectWidget.vue"),
    quickEntityAddWidget: () =>
      import("@/components/widgets/quickEntityAddWidget"),
  },
  computed: {
    filteredEntityFields() {
    return this.selectedEntityFields.filter(
      (field) => field.input_type !== 'DATA_TABLE'
    );
  },
    computedContainerWidth() {
      if (this.getIsMobile) {
        return "100%";
      }
      return this.isDataVisible ? "85vw" : "70vw";
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.aiMatchingResponse.slice(start, end);
    },
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("formBuilders", ["getPublicformBuilderDetails"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityAllRelationshipData",
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("entities", ["getAllEntities", ""]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getAuthenticationStatus",
    ]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
      "getDuplicateSelectedEntityData",
    ]),
    ...mapGetters("entities", ["getEntityById"]),
    ...mapGetters("entitiesData", ["getCreateDocumentForEntityData"]),
    paginationProps() {
      return [5, 10, 20, 50, 100];
    },
    getButtonColor() {
      if (this.data?.styles?.label_color) {
        return this.data.styles.label_color;
      }
      return "black";
    },
    getBackgroundColor() {
      if (this.data?.styles?.input_background) {
        return this.data.styles.input_background;
      }
      return "#285ED3";
    },
    getGalleryStyles() {
      return "font-size: 12px; color: #409EFF;";
    },
    getStyle() {
      if (this.hideStyles) {
        return "";
      }
      let style = `color:${this.getButtonColor};`;
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        if (this.data?.styles?.font?.name) {
          borderStyle += "font-family:" + this.data.styles.font.name + ";";
        }
      }
      if (this.data?.action_button_type != "HYPERLINK") {
        style += `background-color: ${this.getBackgroundColor} !important;`;
      }
      style += borderStyle;
      return style;
    },
    getSize() {
      if (this.data?.styles?.button_size == "Mini") {
        return "mini";
      } else if (this.data?.styles?.button_size == "Small") {
        return "small";
      } else if (this.data?.styles?.button_size == "Large") {
        return "large";
      }
      return "small";
    },
    isRound() {
      if (this.data?.styles?.button_type == "Rounded") {
        return true;
      }
      return false;
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      return false;
    },
    checkDisabled() {
      return this.selectedChildEntitiesData &&
        this.selectedChildEntitiesData.length
        ? false
        : true;
    },
  },
  data() {
    return {
      showViewDialog: false,
      selectedEntityData: null,
      showDialog: false,
      selectedSummary: "",
      checkAiMatch: null,
      isDialogVisible: false,
      isDataVisible: false,
      LoadingDialogVisible: false,
      entity_fields: [],
      selected_fields: [],
      selected_entity_for_aimatch: null,
      customPrompt: "",
      currentPage: 1,
      total: 0,
      pageSize: 5,
      refreshPage: false,
      matchedData: [],
      entityList: [],
      selectedField: null,
      aiMatchingResponse: [],
      otherEntitysDataObj: null,
      validations: [],
      showRepeatingTemplate: false,
      tableData: [],
      selectedTemplateFields: [],
      pageLoading: false,
      entitiesData: [],
      actionButtons: [],
      loading: false,
      tableHeaders: [],
      repeatableTemplate: {},
      openModal: false,
      modalData: null,
      populatedEntitiesData: [],
      isExecute: false,
      inLineForm: {},
      editIndex: -1,
      entitie: [],
      entityFields: [],
      hasLabel: false,
      otherActionUrl: "",
      allowedRelationships: [],
      actions: [],
      navigationAction: null,
      userEntityDataId: "",
      activeContactType: "",
      openRelationalModal: false,
      openRelationalModalForAiMatch: false,
      relationshipsData: [],
      relationshipDetails: {},
      selectedChildEntitiesData: [],
      entities: [],
      selectedEntityFields: [],
      existedRelationshipsMap: [],
      selectedEntityFieldsObj: null,
      authenticationStatus: false,
      allowNewAddOnly: false,
      allowNewAdd: false,
      viewDataOnly: false,
      viewDataAndAdd: false,
      unAssignedFieldDetails: [],
      openOtherEntityModal: false,
      currentEntity: null,
      selectedEntity: null,
      isFromAddOtherEntity: false,
      parent_entityId: null,
      parent_entityDataId: null,
      fieldValue: null,
      checkRelationshipsOnForm: null,
      currentDataId: this.entityDataId,
      checkEditData: null,
      otherEntityDataId: null,
      isUpdateEntityData: false,
      selectedOtherEntityId: null,
      loadingText: "",
      checkDownloadData: null,
      isFromMenuTemplate: false,
    };
  },
  async mounted() {
    if (this.$route.query.selectedRowData) {
      this.selectedRowData = JSON.parse(this.$route.query.selectedRowData);
    }

    if (this.getActiveContactType?.contact_type?._id) {
      this.activeContactType = this.getActiveContactType?.contact_type?._id;
      this.userEntityDataId = this.getActiveContactType.account_data_id;
    }
    if (this.$route?.name == "ViewMenuTemplate") {
      this.isFromMenuTemplate = true;
    }
    let checkNavigation = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "NAVIGATION"
    );
    let checkRelationships = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "ESTABLISH_RELATIONSHIP"
    );
    let checkIsAdd = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "ADD_OTHER_ENTITY"
    );
    let checkIsEdit = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "EDIT_OTHER_ENTITY"
    );
    let checkIsDownload = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "DOWNLOAD_DATA"
    );
    this.checkAiMatch = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "AI_MATCH"
    );
    if (this.checkAiMatch) {
      await this.fetchEntityDetails(this.checkAiMatch, false);
    }
    if (checkRelationships) {
      if (checkRelationships.relationship_other_entity == "self_entity") {
        this.parent_entityId = this.data.parent_entityId;
        this.parent_entityDataId = this.data.parent_entityDataId;
      } else {
        if (checkRelationships?.relationship_other_entity) {
          let [e, f] = checkRelationships.relationship_other_entity.split("#");
          if (e && f) {
            this.parent_entityId = e;
            this.parent_entityDataId = this.form[f];
            this.fieldValue = this.form[f];
            this.checkRelationshipsOnForm = checkRelationships;
          }
        }
      }
    }
    if (this.parent_entityId) {
      this.checkEntityRelationships(
        this.parent_entityId,
        this.parent_entityDataId
      );
    }
    if (checkIsAdd && checkIsAdd?.selected_other_entity) {
      this.selectedOtherEntityId = checkIsAdd.selected_other_entity;
    }
    if (
      checkIsEdit &&
      checkIsEdit?.selected_other_entity_for_edit?.includes("#")
    ) {
      this.checkEditData = checkIsEdit;
      this.isUpdateEntityData = true;
      if (this.checkEditData?.selected_other_entity_for_edit?.split("#")[0]) {
        this.selectedOtherEntityId =
          this.checkEditData.selected_other_entity_for_edit.split("#")[0];
      }
      this.setAndUpdateData();
    }
    if (checkIsDownload) {
      if (checkIsDownload.selected_entity_for_download == "self_entity") {
        this.parent_entityId = this.data.parent_entityId;
        this.parent_entityDataId = this.data.parent_entityDataId;
      } else if (
        checkIsDownload.selected_entity_for_download == "current_template"
      ) {
        //current template do nothing
      } else {
        if (checkIsDownload.selected_entity_for_download) {
          this.checkDownloadData = checkIsDownload;
          this.setAndUpdateData();
        }
      }
    }
    if (checkNavigation) {
      this.navigationAction = checkNavigation;
      switch (checkNavigation?.action_button_action_navigation_type) {
        case "OPEN_FORM_BUILDER":
          this.fetchFormbuilder(checkNavigation.action_button_formbuilder_id);
          break;
        case "OPEN_DOCUMENT_TEMPLATE_BUILDER":
          this.fetchDocumentTemplate(
            checkNavigation.action_button_document_template_id
          );
          break;
        case "OPEN_ENTITY":
          this.otherActionUrl =
            appConfig.APP_URL +
            "/entity/" +
            checkNavigation.action_button_entity_id +
            (checkNavigation?.action_button_entity_filter_id &&
            checkNavigation.action_button_entity_filter_id
              ? `?filter=${checkNavigation.action_button_entity_filter_id}`
              : "");
          break;
        case "OPEN_ENTITY_VIEW":
          this.otherActionUrl =
            appConfig.APP_URL +
            "/entity-views/view/" +
            checkNavigation.action_button_entity_view_id;
          break;
      }
    }
    //remove navigation action
    this.actions = (this.data.actions || []).filter(
      (e) => e.action_button_action_type != "NAVIGATION"
    );
    if (this.fromQuickAction) {
      this.action();
    }
  },
  watch: {
    form: {
      handler() {
        if (this.checkRelationshipsOnForm) {
          let [e, f] =
            this.checkRelationshipsOnForm.relationship_other_entity.split("#");
          if (this.fieldValue !== this.form[f]) {
            this.parent_entityId = e;
            this.parent_entityDataId = this.form[f];
            this.fieldValue = this.form[f];
            if (this.parent_entityDataId) {
              this.checkEntityRelationships(
                this.parent_entityId,
                this.parent_entityDataId
              );
            }
          }
        }
        if (this.checkEditData || this.checkDownloadData) {
          this.setAndUpdateData();
        }
      },
      deep: true,
    },
  },
  methods: {
    handleSelectAllUnselectAll() {
    if (this.selected_fields.includes("selectAllOptions")) {
      this.selected_fields = this.filteredEntityFields.map((item) => item.key);
    }
    else if (this.selected_fields.includes("unselectAllOptions")) {
      this.selected_fields = [];
    }
    this.selected_fields = this.selected_fields.filter(
      (field) => field !== "selectAllOptions" && field !== "unselectAllOptions"
    );
  },
    openSummaryDialog(fullText) {
      this.selectedSummary = fullText;
      this.showDialog = true;
    },
    truncateText(text, length = 100) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    getMatchedAttributesTooltip(fieldKey) {
      const matchedAttributes = new Set();
      const lowercaseSet = new Set();

      this.aiMatchingResponse.forEach((row) => {
        if (this.isMatched(row.result?.Matched_Attributes, fieldKey)) {
          let attributeValue = this.getCurrentFieldValue(row, fieldKey);

          const lowerCaseValue = String(attributeValue).toLowerCase();
          if (!lowercaseSet.has(lowerCaseValue)) {
            matchedAttributes.add(attributeValue);
            lowercaseSet.add(lowerCaseValue);
          }
        }
      });
      const matchedArray = Array.from(matchedAttributes);
      return matchedArray.length > 1
        ? matchedArray.join(" / ")
        : matchedArray.join(", ");
    },
    isMatched(matchedAttributes, fieldKey) {
      return matchedAttributes.includes(fieldKey);
    },

    isNonMatched(nonMatchedAttributes, fieldKey) {
      return nonMatchedAttributes.includes(fieldKey);
    },
    openDialog() {
      this.isDialogVisible = true;
      if (!this.matchedData.length) {
        this.generateResponse();
      }
    },

    viewData(row) {
      this.selectedEntityData = row;
      this.showViewDialog = true;
    },
    handleCloseViewDialog() {
      this.showViewDialog = false;
    },
    redirectToEntityData(row) {
      const entityId = this.checkAiMatch.selected_entity_for_aimatch;
      const query = {
        dataId: row._id,
        filter: this.currentFilter,
        menu_id: this.$route.params.menuId,
        page: this.$route.query.page || 1,
        type: "AUfYWb7SC6U=",
        relationship_id: this.$route.params.entity_id,
        close_tab: "true",
      };

      const path = this.isApplicationUserSide
        ? `/ap/entity-view/${this.relationshipDetails.relationship_id}`
        : `/entity/view/${entityId}`;

      const routerData = this.$router.resolve({ path, query });
      window.open(routerData.href, "_blank");
    },

    getFieldLabel(fieldKey) {
      const field = this.selectedEntityFields.find((f) => f.key === fieldKey);
      return field ? ` ${field.label}` : fieldKey;
    },
    getCurrentFieldValue(data, fieldKey) {
  let entityData = this.otherEntitysDataObj?.[data._id];
  if (!entityData) return "";
  if (
    entityData[fieldKey.split("#")[0]]?.[fieldKey.split("#")[1]] &&
    this.selectedEntityFieldsObj[fieldKey]
  ) {
    let field = JSON.parse(
      JSON.stringify(this.selectedEntityFieldsObj[fieldKey])
    );
    if (field.key.includes("#")) field.key = field.key.split("#")[1];

    const fieldData = this.getFormattedFieldData(
      field,
      entityData[fieldKey.split("#")[0]]
    );
    
    if (Array.isArray(fieldData)) {
      return fieldData.join(", ");
    }

    return fieldData || "";
  }
},

    async aiMatchData(action) {
      this.selected_entity_for_aimatch = action.selected_entity_for_aimatch;
      this.customPrompt = action.customPrompt;

      await this.handleEntityChange(action.selected_entity_for_aimatch);
      this.selected_fields = action.selected_fields;
    },
    async handleEntityChange(newEntityId) {
      try {
        this.selected_fields = [];
        this.selected_entity_for_aimatch = newEntityId;

        await this.fetchEntityDetails(this.selected_entity_for_aimatch);
      } catch (error) {
        console.error("Error handling entity change:", error);
      }
    },

    async fetchEntityDetails(entityId, reset = true) {
      try {
        this.loading = true;
        if (
          !entityId ||
          (typeof entityId !== "string" && typeof entityId !== "number")
        ) {
          this.loading = false;
          return;
        }

        this.entity_fields = [];

        const entityData = await fetchEntityById(entityId);

        if (entityData) {
          this.selectedEntity = { ...entityData };
          this.selectedEntityFields = this.getAllFieldsFromEntity(entityData);
          this.selectedEntityFieldsObj = {};
          (this.selectedEntityFields || []).map((e) => {
            this.selectedEntityFieldsObj[e.key] = e;
          });

          if (reset || !this.entity_fields.length) {
            this.$set(this, "entity_fields", []);
          }
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error("Error fetching entity details:", err);
      }
    },

    async fetchSelectedFieldsData(entityId, entityDataIds = []) {
      this.loading = true;

      const params = {
        entity_id: entityId,
        filters: this.filters || [],
        entity_data_ids: entityDataIds || [],
      };

      try {
        const entitiesData = await postAPICall(
          "POST",
          "/entities-data/entity/data",
          params
        );
        if (entitiesData?.data) return entitiesData.data;
        return [];
      } catch (error) {
        console.error("Error fetching selected fields data:", error);
      } finally {
        this.loading = false;
      }
    },
    async generateResponse() {
      this.loading = true;
      this.LoadingDialogVisible = true;
      try {
        if (!this.selected_entity_for_aimatch) {
          throw new Error("No valid entity selected for AI match.");
        }

        if (!this.selected_fields.length) {
          throw new Error("No valid fields selected for AI match.");
        }

        const payload = {
          session_id: `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`,
          entityId: this.selected_entity_for_aimatch,
          entityDataId: this.entityData._id,
          fieldKeys: this.selected_fields,
          user_prompt: this.customPrompt,
        };
        const response = await axios.post(
          "https://ai.esigns.io/matcher/",
          payload
        );

        if (response?.data) {
          this.$message.success("AI Match generated successfully");
          this.aiMatchingResponse = response.data.Overall_Results;
          let dataIds = (response.data.Overall_Results || []).map((e) => e._id);
          let otherEntityData = await this.fetchSelectedFieldsData(
            this.selected_entity_for_aimatch,
            dataIds
          );
          if (otherEntityData?.length) {
            this.otherEntitysDataObj = {};
            otherEntityData.map((e) => {
              this.otherEntitysDataObj[e._id] = e.entityData;
            });
          }
          this.isDataVisible = true;
        }
      } catch (error) {
        console.error(
          "Error during AI Match generation:",
          error.message || error
        );
        this.$message.error(
          error.message || "Error generating AI Match. Please try again."
        );
      } finally {
        this.loading = false;
        this.LoadingDialogVisible = false;
      }
    },
    getProgressColor(percentage) {
      if (percentage >= 80) {
        return "#13ce66";
      } else if (percentage >= 50) {
        return "#ff9900";
      } else {
        return "#ff4949";
      }
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1;
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
    },
    setAndUpdateData() {
      if (this.checkEditData) {
        let [e, f] =
          this.checkEditData.selected_other_entity_for_edit.split("#");
        if (this.fieldValue !== this.form[f]) {
          this.parent_entityId = e;
          this.parent_entityDataId = this.form[f];
          this.fieldValue = this.form[f];
          if (this.parent_entityDataId) {
            this.checkEntityRelationships(
              this.parent_entityId,
              this.parent_entityDataId
            );
          }
        }
      } else if (this.checkDownloadData) {
        let [e, f] =
          this.checkDownloadData.selected_entity_for_download.split("#");
        if (this.fieldValue !== this.form[f]) {
          this.parent_entityId = e;
          this.parent_entityDataId = this.form[f];
          this.fieldValue = this.form[f];
        }
      }
    },
    async updateRelationships() {
      let relationEntity = this.actions.find(
        (e) =>
          e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
          e.relational_entity_one
      );
      if (
        this.activeContactType == relationEntity?.relational_entity_one &&
        this.userEntityDataId
      ) {
        this.selectedChildEntitiesData = [this.userEntityDataId];
        this.establichRelationship();
      } else if (this.relationshipDetails) {
        if (relationEntity.action_button_relationship_type == 3) {
          this.allowNewAdd = true;
        }
        if (relationEntity.action_button_relationship_type == 2) {
          this.allowNewAddOnly = true;
          this.allowNewAdd = true;
        }
        if (relationEntity.action_button_relationship_type == 4) {
          this.viewDataOnly = true;
          this.allowNewAddOnly = false;
          this.allowNewAdd = false;
        }
        if (relationEntity.action_button_relationship_type == 5) {
          this.viewDataAndAdd = true;
        }
        this.openRelationalModal = true;
      } else {
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        }
      }
    },
    async establichRelationship() {
      this.loading = true;
      let data;
      if (this.relationshipDetails.owner_type == "PARENT") {
        data = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_data_ids: this.selectedChildEntitiesData,
        };
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          data = {
            parent_entity_id: this.relationshipDetails.child_entity_id,
            child_entity_id: this.relationshipDetails.parent_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: this.selectedChildEntitiesData,
          };
        } else {
          data = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id: this.selectedChildEntitiesData[0],
            child_entity_data_ids: [
              this.relationshipDetails.parent_entity_data_id,
            ],
          };
        }
        if (
          this.relationshipDetails.representation == "FIELD" &&
          this.unAssignedFieldDetails &&
          this.unAssignedFieldDetails.length
        ) {
          data.assign_field_data = [];
          this.selectedChildEntitiesData.forEach((entity_data_id) => {
            this.unAssignedFieldDetails.forEach((field) => {
              data.assign_field_data.push({
                ...field,
                entity_data_id,
              });
            });
          });
        }
      }
      await this.$store.dispatch(
        "entityRelationships/assignEntityRelationshipData",
        data
      );

      if (this.getEntityRelationshipDataAssignStatus) {
        this.loading = false;
        this.$message.success(
          `${this.relationshipDetails.relationship_title} data added successfully`
        );
        this.resetAddModal(true);
      } else {
        this.loading = false;
      }
    },
    updateEntityData(data) {
      if (data?.value) {
        if (typeof data.value == "object" && data.value.length) {
          this.selectedChildEntitiesData = [...data.value];
        } else {
          this.selectedChildEntitiesData = [data.value];
        }
      }
      if (data.unAssignedFieldDetails) {
        this.unAssignedFieldDetails = data.unAssignedFieldDetails;
      }
      if (data.update) {
        this.establichRelationship();
      }
    },
    resetAddModal(isRefresh) {
      if (isRefresh) {
        let relationEntity = this.actions.find(
          (e) =>
            e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
            e.relational_entity_one
        );
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        } else if (relationEntity?.call_back) {
          this.afterAction(relationEntity);
        }
        this.$emit("actionExecuted");
      }
      this.selectedChildEntitiesData = [];
      this.openRelationalModal = false;
      this.$emit("closeModal");
    },
    async getExistedRelationshipsData(entityId, entityDataId) {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityAllRelationshipData",
        {
          parent_entity_id: entityId,
          parent_entity_data_id: entityDataId,
        }
      );
      if (this.getEntityAllRelationshipData) {
        this.setEntityRelationshipMapData(
          this.getEntityAllRelationshipData,
          this.relationshipDetails.relationship_id
        );
      }
    },
    setEntityRelationshipMapData(reationshipData, entityId) {
      this.existedRelationshipsMap = [];
      (reationshipData || []).forEach((relation) => {
        if (
          relation?.relationship_id == entityId &&
          relation?.entity_data_ids
        ) {
          this.existedRelationshipsMap = [
            ...this.existedRelationshipsMap,
            ...relation.entity_data_ids.filter((e) => e),
          ];
        }
      });
    },
    async checkEntityRelationships(entityId, entityDataId) {
      this.allowedRelationships = [];
      await Promise.all([
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: entityId,
        }),
      ]);
      if (this.getEntityRelationships) {
        this.setRelationshipDetails(entityId, entityDataId);
      }
    },
    setRelationshipDetails(entityId, entityDataId) {
      this.relationshipsData = [...(this.getEntityRelationships || [])];
      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == entityId) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      // this.relationshipsData = this.relationshipsData.filter(
      //   (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      // );
      let relationEntity = this.actions.find(
        (e) =>
          e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
          e.relational_entity_one
      );
      if (relationEntity) {
        this.relationshipDetails = this.relationshipsData.find(
          (e) => e.relationship_id == relationEntity.relational_entity_one
        );
        if (this.relationshipDetails) {
          this.setAllowOne();
          if (entityDataId) {
            this.getExistedRelationshipsData(entityId, entityDataId);
          }
        }
        if (this.fromQuickAction) {
          this.action();
        }
      }
    },
    setAllowOne() {
      this.relationshipDetails.allowOne = null;

      if (this.relationshipDetails.representation == "TAB") {
        if (
          (this.relationshipDetails.owner_type == "CHILD" &&
            this.relationshipDetails.parent_relation_type == "ONE") ||
          (this.relationshipDetails.owner_type == "PARENT" &&
            this.relationshipDetails.child_relation_type == "ONE")
        ) {
          this.relationshipDetails.allowOne = true;
        } else {
          this.relationshipDetails.allowOne = false;
        }
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          this.relationshipDetails.allowOne = false;
        } else {
          this.relationshipDetails.allowOne = true;
        }
      }
    },
    async fetchDocumentTemplate(template_id) {
      if (template_id && template_id.includes("#")) {
        this.otherActionUrl =
          appConfig.APP_URL +
          `/company-documents/${template_id.split("#")[0]}/configure/template/${
            template_id.split("#")[1]
          }/preview`;
      }
    },
    async fetchFormbuilder(formbuilder_id) {
      this.loading = true;
      this.otherActionUrl = "";
      await this.$store.dispatch("formBuilders/fetchPublicWorkFlowById", {
        id: formbuilder_id,
      });
      if (this.getPublicformBuilderDetails?._id) {
        this.loading = false;
        this.otherActionUrl =
          appConfig.APP_URL +
          "/public/fb" +
          "/" +
          `${this.getPublicformBuilderDetails.code}` +
          "/" +
          `${this.getPublicformBuilderDetails.steps[0].slug}` +
          "/" +
          `${this.getPublicformBuilderDetails._id}` +
          "/auth/verify";

        if (this.getAuthenticatedUser?.is_contact) {
          this.otherActionUrl = "";

          this.otherActionUrl =
            appConfig.APP_URL +
            "/public/fb" +
            "/" +
            `${this.getPublicformBuilderDetails.code}` +
            "/" +
            `${this.getPublicformBuilderDetails.steps[0].slug}` +
            "/" +
            `${this.getPublicformBuilderDetails._id}` +
            "/vp/" +
            this.getAuthenticatedUser._id;
          const queryParams = {
            application_user: "true",
          };

          // Convert the query parameters object to a query string
          const queryString = Object.keys(queryParams)
            .map(
              (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                  queryParams[key]
                )}`
            )
            .join("&");
          this.otherActionUrl = `${this.otherActionUrl}?${queryString}`;
        }
      } else {
        this.loading = false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    openURL(url, action) {
      switch (action.action_button_target_location) {
        case "SELF":
          window.location.href = url;
          break;
        case "NEW_TAB":
          window.open(url, "_blank");
          break;
        case "NEW_WINDOW":
          window.open(url, "MsgWindow", "width=800,height=800");
          break;
        default:
          window.location.href = url;
          break;
      }
    },
    applyFormRules() {
      this.$set(this.form, this.data.key, true);
      this.$emit("applyFormRules");
      let relationAction = (this.actions || []).find(
        (action) => "ESTABLISH_RELATIONSHIP" == action.action_button_action_type
      );
      if (this.navigationAction && !relationAction) {
        this.checkNavigationAction(this.navigationAction);
      }
      // setTimeout(() => {
      //   this.$set(this.form, this.data.key, false);
      // }, 500);
    },
    checkNavigationAction(action) {
      switch (action.action_button_action_navigation_type) {
        case "OPEN_URL":
          if (action.action_button_target_link) {
            if (
              this.isValidURL(action.action_button_target_link) &&
              this.isValidHttpUrl(
                this.getValidUrl(action.action_button_target_link)
              )
            ) {
              let url = this.getValidUrl(action.action_button_target_link);
              this.openURL(url, action);
            } else {
              this.$message.info("Invalid URL");
            }
          } else {
            this.$message.info("No Hyperlink to open");
          }
          break;
        case "OPEN_FORM_BUILDER":
          if (action.action_button_formbuilder_id && this.otherActionUrl) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No form builder selected");
          }
          break;
        case "OPEN_DOCUMENT_TEMPLATE_BUILDER":
          if (
            action.action_button_document_template_id &&
            this.otherActionUrl
          ) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No document template selected");
          }
          break;
        case "OPEN_ENTITY":
          if (action.action_button_entity_id && this.otherActionUrl) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No entity selected");
          }
          break;
        case "OPEN_ENTITY_VIEW":
          if (action.action_button_entity_view_id && this.otherActionUrl) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No entity view selected");
          }
          break;
      }
    },
    async action() {
      let relationshipActionExisted = this.actions.filter(
        (action) =>
          action.action_button_action_type == "ESTABLISH_RELATIONSHIP" ||
          action.action_button_action_type == "CREATE_DUPLICATE"
      );
      relationshipActionExisted.forEach((relationEntity) => {
        if (
          this.isFromMenuTemplate &&
          relationEntity.relationship_other_entity == "self_entity"
        ) {
          this.$message({
            message:
              "Can't Add. This button has been configured to add related data to self entity.",
            type: "warning",
          });
        }
        if (
          this.isFromMenuTemplate &&
          relationEntity.action_button_action_type == "CREATE_DUPLICATE"
        ) {
          this.$message({
            message:
              "Can't Duplicate. This button has been configured to duplicate the entity data.",
            type: "warning",
          });
        }
      });
      if (
        !this.fromQuickAction &&
        relationshipActionExisted?.length &&
        !(this.$route?.query?.dataId || this.entityDataId) &&
        !this.checkRelationshipsOnForm
      ) {
        this.$bus.$on("execute-action", async (dataId) => {
          this.data.parent_entityDataId = dataId;
          await this.checkEntityRelationships(
            this.data.parent_entityId,
            dataId
          );
          if (!this.currentDataId) {
            this.currentDataId = dataId;
          }
          this.executeAction();
        });
        this.$emit("saveTemplateFromActionButton");
      } else if (this.checkRelationshipsOnForm && !this.parent_entityDataId) {
        let k =
          this.checkRelationshipsOnForm.relationship_other_entity.split("#")[1];
        let field = this.fieldsData.find((f) => f.key == k);
        this.$message({
          message: "Warning, Please select " + (field?.label || "data"),
          type: "warning",
        });
      } else if (this.checkEditData && !this.parent_entityDataId) {
        let k = this.checkEditData.selected_other_entity_for_edit.split("#")[1];
        let field = this.fieldsData.find((f) => f.key == k);
        this.$message({
          message: "Warning, Please select " + (field?.label || "data"),
          type: "warning",
        });
      } else if (
        this.checkDownloadData &&
        (!this.parent_entityDataId || this.parent_entityDataId?.length == 0)
      ) {
        let k =
          this.checkDownloadData.selected_entity_for_download.split("#")[1];
        let field = this.fieldsData.find((f) => f.key == k);
        this.$message({
          message: "Warning, Please select " + (field?.label || "data"),
          type: "warning",
        });
      } else {
        this.executeAction();
      }
    },
    async executeAction() {
      if (this.actions && this.actions.length) {
        await (this.actions || []).forEach(async (action) => {
          switch (action.action_button_action_type) {
            case "TEMPLATE_ACTION":
              await this.applyFormRules();
              break;
            case "ESTABLISH_RELATIONSHIP":
              await this.updateRelationships();
              break;
            case "CREATE_DUPLICATE":
              await this.createDuplicateData(action);
              break;
            case "ADD_OTHER_ENTITY":
              await this.addOtherEntityData();
              break;
            case "EDIT_OTHER_ENTITY":
              await this.addOtherEntityData();
              break;
            case "DOWNLOAD_DATA":
              await this.downloadEntityData(action);
              break;
            case "AI_MATCH":
              this.openRelationalModalForAiMatch = true;
              await this.aiMatchData(action);
              break;
          }
        });
      } else {
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        }
      }
      this.$bus.$off("execute-action");
    },
    afterAction(action) {
      this.$emit("actionButtonCallBacks", action.call_back);
    },
    async downloadEntityData(action) {
      this.loading = true;
      this.loadingText = "Generating document...";
      if (action?.selected_entity_for_download == "current_template") {
        await this.downloadCurrentTemplateData();
        this.loading = false;
      } else if (
        this.isFromMenuTemplate &&
        action?.selected_entity_for_download == "self_entity"
      ) {
        this.$message({
          message:
            "Can't download. This button has been configured to download data of the self entity.",
          type: "warning",
        });
        this.loading = false;
      } else {
        let entityId = action?.selected_entity_for_download?.includes("#")
          ? action.selected_entity_for_download.split("#")[0]
          : this.parent_entityId;
        await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: entityId,
        });
        if (this.getEntityById) {
          this.currentEntity = this.getEntityById;
        }
        // When it's self entity download, checking the entity data ID is created or not
        if (!this.parent_entityDataId) {
          this.$message({
            message: "This entity data haven't created, please click on save",
            type: "warning",
          });
          this.loading = false;
          return;
        }
        if (
          this.currentEntity &&
          this.currentEntity.default_printing_document &&
          this.parent_entityDataId
        ) {
          await this.$store.dispatch(
            "entitiesData/createDocumentFromEntityData",
            {
              entity_id: this.currentEntity._id,
              document_id: this.currentEntity.default_printing_document || "",
              ...(typeof this.parent_entityDataId == "object" &&
                this.parent_entityDataId?.length && {
                  entity_data_ids: this.parent_entityDataId,
                }),
              ...(typeof this.parent_entityDataId == "string" &&
                this.parent_entityDataId && {
                  entity_data_id: this.parent_entityDataId,
                }),
            }
          );
          if (
            this.getCreateDocumentForEntityData &&
            this.getCreateDocumentForEntityData.generatedDocuments
          ) {
            if (
              this.getCreateDocumentForEntityData.generatedDocuments?.length > 1
            ) {
              this.downloadMultipleEntityDataPdfs(
                this.getCreateDocumentForEntityData.generatedDocuments
              );
            } else {
              this.downloadEntityDataPdf(
                this.getCreateDocumentForEntityData.generatedDocuments,
                this.currentEntity && this.currentEntity.name
                  ? this.currentEntity.name
                  : "Data "
              );
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              message: "Error while generating document",
              type: "error",
            });
          }
        } else {
          this.loadingText = "";
          this.loading = false;
          this.$message({
            message:
              "Warning, No document template has been configured for " +
              this.currentEntity?.name,
            type: "warning",
          });
        }
      }
    },
    downloadMultipleEntityDataPdfs(pdfUrls) {
      const zip = new JSZip();
      const promises = [];

      pdfUrls.forEach((url, index) => {
        const filename = `document_${index + 1}.pdf`;
        promises.push(
          axios
            .get(url, { responseType: "blob" })
            .then((response) => zip.file(filename, response.data))
        );
      });

      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "documents.zip";
            a.click();
            window.URL.revokeObjectURL(url);
          });
        })
        .catch((error) => {
          console.log("Error while downloading files:", error);
        });
    },
    async downloadCurrentTemplateData() {
      try {
        this.loading = true;
        let hasNonEmptyValue = Object.keys(this.form).some((key) => {
          const value = this.form[key];
          return (
            value !== null &&
            value !== "" &&
            (!Array.isArray(value) || value.length > 0)
          );
        });
        if (hasNonEmptyValue) {
          let response = await postAPICall(
            "POST",
            "/entities-data/template-document-printing",
            {
              template_id: this.selfTemplateId,
              form: this.form,
            }
          );
          if (response?.generatedDocuments) {
            this.downloadEntityDataPdf(
              response.generatedDocuments,
              this.templateName ? this.templateName : "Data"
            );
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              message: "Error while generating document",
              type: "error",
            });
          }
        } else {
          this.$message({
            message: "Please fill at least one field",
            type: "warning",
          });
        }
        this.loading = false;
      } catch (err) {
        this.$message({
          message: "Please configure document template",
          type: "warning",
        });
      }
    },

    async addOtherEntityData() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
        entity_id: this.selectedOtherEntityId,
      });
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        this.openOtherEntityModal = true;
        this.isFromAddOtherEntity = true;
        this.loading = false;
      }
      this.loading = false;
    },
    resetOtherEntityModal(isAdded = false) {
      this.openOtherEntityModal = false;
      if (isAdded) this.$emit("actionExecuted");
    },
    async createDuplicateData(action) {
      if (this.isView) {
        return this.$message.warning(
          "can't perform duplicate data action in view mode"
        );
      } else if (this.entity_type == "INDIVIDUAL") {
        return this.$message.warning(
          "can't duplicate data in individual entity"
        );
      }
      let fieldsDataObject = {};
      this.fieldsData.map((e) => {
        fieldsDataObject[`${e.template_id}#${e.key}`] = e;
      });
      let data = {
        entity_id: this.entityId,
        existed_data_id: this.currentDataId,
        current_date: this.getCurrentDate,
        selected_fields: action?.selected_fields || [],
        data_table_selected_rows: this.selectedTableRows,
        current_template_id: this.fieldsData?.[0]?.template_id,
      };
      let isValid = true;
      action.selected_fields.map((e) => {
        let field = fieldsDataObject[e];
        if (
          (field.input_type == "DATA_TABLE" ||
            field.inputType == "DATA_TABLE") &&
          !data.data_table_selected_rows[e]?.length
        ) {
          isValid = false;
          this.$message.warning(`Please select ${field.label} rows`);
        }
      });
      if (isValid) {
        this.$emit("loadComponent", {
          value: true,
          loadingText: "Creating New Data....",
        });
        await this.$store.dispatch(
          "templatesData/duplicateSelectedEntityData",
          data
        );
        if (this.getDuplicateSelectedEntityData?.success) {
          this.setSelectedTableRows();
          this.$notify.success({
            title: "Success",
            message: "Data Duplicated successfully",
          });
          await this.applyFormRules();
        } else {
          this.previewComponentLoading = false;
          this.$notify.error({
            title: "Error",
            message: this.getDuplicateSelectedEntityData.message,
          });
        }
        this.$emit("loadComponent", { value: false, loadingText: "" });
      }
    },
    setSelectedTableRows() {
      Object.keys(this.selectedTableRows).map(
        (key) => (this.selectedTableRows[key] = [])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 3px 0px 3px 0px !important;
  line-height: 20px;
}
.container-fields {
  padding: 10px;
}
.scan-sty {
  position: relative;
  bottom: 3px;
}
.custom-pagination {
  padding: 10px;
  margin-left: 27%;
}
.no-data-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  flex-direction: column;
}

.no-data-content {
  text-align: center;
}

.no-data-icon {
  width: 100px;
}

p {
  color: #777;
  font-size: 16px;
}
.custom-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.custom-popup-box {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.import-loading-gif {
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 8px;
  font-weight: bold;
}
.el-table th {
  white-space: normal;
  word-break: break-word;
  text-align: center;
}
.data-box {
  background-color: #f5f7fa;
  padding: 20px;
  border: 1px solid #ebeef5;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 700px) {
  .el-col-6 {
    width: 100%;
  }
}
.el-table {
  table-layout: auto;
}

.el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary-cell {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-fields {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>